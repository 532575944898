import styled, { css } from "styled-components";
import Title from "../../Commons/Title";

type ForgeType = {
  logo: string;
};

export const TitleWrapper = styled.div`
  margin-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  ${Title} {
    z-index: 2;
  }
`;

export const Forge = styled.div<ForgeType>`
  background-image: url("${(props) => props.logo}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 41px;
  height: 93px;
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translate(-50%, 0);
  z-index: 1;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 60px;
    height: 130px;
  }
`;

export const TitleInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #a3a3a3;
  text-transform: uppercase;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    font-size: 1.125rem;
    line-height: 1.2;
  }
`;

export const InfoWrapper = styled(TitleInfo)`
  flex-direction: row;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-top: 16px;
  letter-spacing: 0.15rem;
`;

export const CatDivider = styled.div`
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin-right: 5px;
    margin-left: 5px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const AuthorWrapper = styled(TitleInfo)`
  font-weight: 400;
  letter-spacing: 0.1rem;
  margin: 4px 0 20px;
`;

export const FeaturedBanner = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 69px;
  img {
    max-width: 100%;
  }
`;

export const ArticleRichText = styled.div`
  font-family: proxima-nova;
  font-weight: 500;
  font-style: normal;
  position: relative;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;

  h1,
  h2,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 30px;
  }

  h2,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }

  h1,
  h2,
  h3 h4,
  h5,
  h6 {
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      line-height: 1.2;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      line-height: 1.5;
    }
  }

  h1 {
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      font-size: 1.375rem;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 2.125rem;
    }
  }

  h2,
  h3 {
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      font-size: 1.125rem;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 1.5rem;
    }
  }

  /* subtitle */
  h3 {
    color: #8b8682;
  }

  h4 {
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      font-size: 0.875rem;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 1.25rem;
    }
  }

  h5,
  h6 {
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      font-size: 0.75rem;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 1rem;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 20px;
    letter-spacing: 3%;
    color: #8b8682;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      font-size: 0.875rem;
      line-height: 21px;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 18px;
      line-height: 32px;
    }

    + p {
      margin-top: 20px;
    }

    &:empty {
      margin: 0;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  ul,
  ol {
    margin: 20px 45px;
    padding: 0;
    padding-left: 0 !important;

    + ul,
    + ol {
      margin-bottom: 0;
    }

    ul,
    ol {
      margin: 0;
      padding-left: 20px;
    }

    li {
      position: relative;
    }
  }

  ol {
    list-style: auto;
  }

  ul {
    list-style: disc;
  }

  figure {
    margin: 0;
    width: 100% !important;
    max-width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  a {
    color: ${({ theme }) => theme.color_text_accent};
    text-decoration: underline;

    &:visited {
      color: ${({ theme }) => theme.color_text_dimmed};
    }
  }

  table {
    border-collapse: collapse;
    margin: 20px 0;
    position: relative;
    width: calc(100% - 1px);
  }

  th {
    font-weight: 900;
    color: #30353f;
    padding: 20px;
    text-align: left;
  }

  th,
  td {
    border: 1px solid black;
  }

  td {
    padding: 20px;
    width: 177px;
  }

  figure {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: left;

    img {
      height: auto;
    }

    figcaption {
      text-align: left !important;
      font-size: 12px;
      padding: 10px 0 20px;
      color: #8b8682;
    }
  }
`;

export const ArticleMediaContent = styled.div`
  margin-bottom: 40px;
`;

export const GalleryTitle = styled.div`
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: bold;
`;

export const ArticleCarouselContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 55px 0;
`;

export const Seperator = styled.div`
  position: relative;
  padding: 0;
  margin: 6rem 0;
  border: none;
  border-bottom: 1px solid #8b8682;

  &[data-separator="Simple"] {
    height: 1px;
    background-color: $colorGrey;
  }
`;

export const TabContainer = styled.div``;
