import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { wrapIFrameWithinRichText } from "../../../../utils/rich-text";
import * as S from "./style";
import { ArticleRichText } from "../../NewsArticleContent/styles";

export interface TabsProps {
  tab_configuration: Array<{
    tab_content: string;
    tab_name: string;
  }>;
}

const padding = {
  padding: "2.4rem 1.7rem",
};

const Tabs: React.FC<TabsProps> = ({ tab_configuration: tabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabContent, setTabContent] = useState(tabs[activeTabIndex].tab_content);

  useEffect(() => {
    setTabContent(wrapIFrameWithinRichText(tabs[activeTabIndex].tab_content));
  }, [activeTabIndex]);

  return (
    <S.TabsBlock>
      <div className="tabsWrapper">
        <div className="tabsHeaderWrapper">
          <ul className="tabsHeader">
            {tabs.map((tab, index) => (
              <li key={index} className={`${"tabsItem"}`}>
                <S.TabButton
                  className={`${activeTabIndex === index ? "isActive" : ""}`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {tab.tab_name}
                </S.TabButton>
              </li>
            ))}
          </ul>
        </div>
        <ArticleRichText style={padding}>{parse(tabContent)}</ArticleRichText>
      </div>
    </S.TabsBlock>
  );
};

export default Tabs;
