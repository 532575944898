import styled, { css } from "styled-components";
import Title from "../../Commons/Title";

type ForgeType = {
  logo: string;
};

export const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    max-width: 100%;
    height: 90%;
  }
`;

export const FooterSeperator = styled.div`
  position: relative;
  padding: 0;
  border: none;
  border-bottom: 1px solid #8b8682;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin: 4rem 0;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin: 5rem 0;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin: 6rem 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    letter-spacing: 2px;
  }
  svg {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    flex-direction: row;
    margin: 10vw;

    .textLink {
      margin-left: 24px;
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;

  svg {
    margin-left: 0;
    margin-right: 10px;
    position: relative;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
      height: 32px;
      margin-right: 16px;
      width: 32px;
      left: -6px;
    }
  }
`

export const Forge = styled.div<ForgeType>`
  background-image: url("${(props) => props.logo}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 59px;
  height: 110px;
  position: absolute;
  left: 50%;
  top: -100%;
  transform: translate(-50%, 0);
  z-index: 1;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 60px;
    height: 115px;
    top: -34%;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  ${Title} {
    z-index: 2;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 75px;
  }
`;
