import styled, { css } from "styled-components";

export const TabsBlock = styled.div`
  overflow: hidden;
  margin-bottom: 40px;

  .tabsWrapper {
    border: 1px solid grey;
    position: relative;

    &:before {
      height: 0.6rem;
      width: 0.6rem;
      color: black;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &:after {
      height: 18.5%;
      height: 5px;
      color: black;
      width: 0.6rem;
      position: absolute;
      bottom: -0.5rem;
      left: -1px;
      z-index: 1;
    }

    .tabsItem {
      flex-shrink: 0;
      font-size: 1.5rem;
      line-height: 1.25;
      letter-spacing: -0.025em;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 4rem;
      }

      &:first-child {
        padding-left: 2.5rem;
      }

      &:last-child {
        padding-right: 2.5rem;
      }

      @include hover {
        .tabButton {
          &:not(.isActive) {
            transform: translateY(-20%);
          }
        }
      }
    }

    .tabsHeaderWrapper {
      display: flex;
      position: relative;
      width: 100%;
      overflow-x: auto;
      padding: 4rem 0 2.5rem;
      background-color: #d7d3ca;

      .tabsHeader {
        display: flex;
        margin: 0 auto;

        .tabsItem {
          &::before {
            display: none;
          }
        }
      }
    }
  }
`;

export const TabButton = styled.div`
  position: relative;
  text-transform: uppercase;
  border: none;
  background: transparent;
  outline: none;
  font-size: 1rem;
  transition: transform 0.2s ease-out;

  &::after {
    position: absolute;
    bottom: -1rem;
    left: 0;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.2s ease-out;
  }

  &.isActive {
    &::after {
      transform: scaleX(1);

    }
  }
`;
