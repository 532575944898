import React from "react";
import { ArticleContentData } from "../../../data/ContentStack";
import * as S from "./styles";

interface NewsArticleHeroProps extends ArticleContentData {}

const NewsArticleHero: React.FC<NewsArticleHeroProps> = ({ banner }) => {
  return (
    <S.Section>
      <S.ClippedContainer>
        <S.HeroBackground imageUrl={banner.hero_banner_url.url} />
      </S.ClippedContainer>

      <S.Wrapper>
        <S.HeroImage>
          <picture>
            <img className="image" src={banner.featured_banner_url.url} />
          </picture>
        </S.HeroImage>
      </S.Wrapper>

      <S.SVGLightningLines width="1440" height="270" viewBox="0 0 1440 270" fill="none">
        <path d="M-942 301.5L167.5 138L151 234L1718.5 1" stroke="white" />
      </S.SVGLightningLines>
      <S.SVGLightningLinesCrossed width="1440" height="277" viewBox="0 0 1440 277" fill="none">
        <path d="M-935.701 301.5L173.799 138L157.299 234L1724.8 1" stroke="white" />
      </S.SVGLightningLinesCrossed>
    </S.Section>
  );
};

export default NewsArticleHero;
