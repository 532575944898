import styled, { css } from "styled-components";

type HeroBackgroundProps = {
  imageUrl: string;
};

export const Section = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    height: 65vw;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 45rem;
  }
`;

export const ClippedContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  z-index: 1;
  clip-path: polygon(100% 0, 100% 78%, 0 97%, 0 0);

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 14.5vw),
      8.85% calc(100% - 1vw),
      10% calc(100% - 7.75vw),
      0 calc(100% - 6.2vw)
    );
  }
`;

export const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  bottom: 1vw;
  height: auto;
  z-index: 1;
  opacity: 0.3;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: inline-block;
    width: 100%;
    left: 0vw;
  }
`;

export const SVGLightningLinesCrossed = styled.svg`
  display: block;
  position: absolute;
  bottom: 0vw;
  height: auto;
  z-index: 1;
  opacity: 0.3;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: inline-block;
    width: 100%;
    left: 0vw;
  }
`;

export const HeroBackground = styled.div<HeroBackgroundProps>`
  background-image: ${(props) => (props.imageUrl ? `url(${props.imageUrl})` : "none")};
  background-size: cover;
  filter: blur(14px);
  position: absolute;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    height: 100%;
    top: -7%;
    left: -5%;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    height: 100%;
    top: -4%;
    left: -2%;
    width: 104%;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 109%;
    top: -3%;
    left: -3%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const HeroImage = styled.div`
  display: flex;
  justify-content: center;

  .image {
    border: 3px solid black;
    width: 100%;
    max-width: 1061px;
    height: auto;
    position: relative;
    z-index: 10;
    top: -113%;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: 100%;
    padding: 0 20px;
    height: auto;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 100%;
    padding: 0;
    height: auto;
  }
`;
