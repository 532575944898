import React from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination} from "swiper/core";
import * as S from "./styles";
import PrevArrow from "../../../../images/article/swiper-prev.svg";
import NextArrow from "../../../../images/article/swiper-next.svg";

SwiperCore.use([Navigation, Pagination]);
export interface ImageSlide {
  image: {
    url: string;
  };
  title?: string;
}
interface ImageCarouselProps {
  images: ImageSlide[];
}
const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [swiperIndex, setSwiperIndex] = useState<number>(0);

  const renderCaption = () => {
    return images &&
      <S.Caption key={swiperIndex}>
        <p>{images[swiperIndex].title}</p>
      </S.Caption>
  }

  return (
    <S.SwiperWrapper>
      <div className={`swiper-wrap`}>
        <Swiper
          pagination={{
            type: "fraction",
            el: '.swiper-pagination',
          }}
          autoHeight={true}
          slidesPerView={1}
          spaceBetween={30}
          effect={"fade"}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          onSlideChange={(swiperCore: { realIndex: number | string }) => {
            const { realIndex } = swiperCore;
            const index:number = typeof realIndex === 'string' ? parseInt(realIndex) : realIndex
            setSwiperIndex(index);
          }}
        >
          {images.map(({ image, title }, index) => {
            if (!image) {
              throw new Error(`Error: Missing image #${index + 1} in Gallery: ${JSON.stringify(images[index])}`);
            }
            return (
              <SwiperSlide key={`image-${index}`}>
                {/* ContentstackIMG was not playing nice with Swiper */}
                <img src={image.url} />
              </SwiperSlide>
            );
          })}
          <div className="swiper-button-prev">
            <PrevArrow />
          </div>
          <div className="swiper-button-next">
            <NextArrow />
          </div>
          <div className="swiper-pagination"></div>
        </Swiper>
        {renderCaption()}
      </div>
    </S.SwiperWrapper>
  )
}
export default ImageCarousel;
