import React from "react";
import styled from "styled-components";

const YouTubeVideo = styled.div`
  > iframe {
    @include size(100%);
  }
`;

interface YoutubeVideoProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  title?: string;
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ src, title }) => (
  <YouTubeVideo>
    <iframe src={src} height="500px" width="100%" frameBorder="0" allow="autoplay" title={title} />
  </YouTubeVideo>
);

export default YoutubeVideo;
