import parse from "html-react-parser";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useContext, Fragment } from "react";
import { localeDateString } from "../../../utils/date-utils";
import { ArticleContentData } from "../../../data/ContentStack";
import Title from "../../Commons/Title";
import YouTubeVideo from "../content/YouTubeVideo/index";
import ImageCarousel from "../content/ImageCarousel";
import Tabs from "../content/Tabs";
import { Container, Row, Col } from "react-awesome-styled-grid";

import * as S from "./styles";

interface ArticleContentProps extends ArticleContentData {}

const ArticleContent: React.FC<ArticleContentProps> = ({ date, authors, category, title, article_body: blocks }) => {
  const { language } = useI18next();
  const localeDate = localeDateString(date, language);

  return (
    <Container>
      <Row justify="center">
        <Col xs={2} sm={2} md={6} lg={8} xl={8}>
          <Row justify="left">
            <Col xs={2} sm={4} md={8} lg={12} xl={12}>
              <S.TitleWrapper className="animation-opacity">
                <Title level={1} variable="primary" color="black" uppercase>
                  {title}
                </Title>
                <S.TitleInfo>
                  <S.InfoWrapper>
                    {category && category[0].title} <S.CatDivider>|</S.CatDivider>{" "}
                    {date && <span className="date">{localeDate}</span>}
                  </S.InfoWrapper>
                  {authors && <S.AuthorWrapper>{authors[0]?.title}</S.AuthorWrapper>}
                </S.TitleInfo>
              </S.TitleWrapper>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={2} sm={2} md={6} lg={8} xl={8}>
          {blocks?.map(
            (
              { rich_text_editor: richTextEditor, video_youtube: videoYoutube, gallery, separator, tabs }: any,
              index: any,
            ) => {
              if (richTextEditor) {
                return (
                  <S.ArticleRichText key={`article content ${index}`}>
                    {parse(richTextEditor?.rich_text as string)}
                  </S.ArticleRichText>
                );
              } else if (videoYoutube) {
                return (
                  <Fragment key={`article content youtube ${index}`}>
                    <Row justify="center">
                      <Col xs={2} sm={2} md={10} lg={12} xl={12}>
                        <S.ArticleMediaContent>
                          <YouTubeVideo
                            src={`https://www.youtube.com/embed/${videoYoutube?.video_id}?autoplay=0&mute=0&rel=0&modestbranding=1`}
                          />
                        </S.ArticleMediaContent>
                      </Col>
                    </Row>
                  </Fragment>
                );
              } else if (gallery) {
                return (
                  <S.ArticleCarouselContent key={`article content gallery ${index}`}>
                    {gallery.title && <S.GalleryTitle>{gallery.title}</S.GalleryTitle>}
                    <ImageCarousel images={gallery.images} />
                  </S.ArticleCarouselContent>
                );
              } else if (separator) {
                return <S.Seperator key={`article content separator ${index}`} data-separator={separator?.type} />;
              } else if (tabs) {
                return (
                  <S.TabContainer key={`article content tabs ${index}`}>
                    <Tabs tab_configuration={tabs.tab_configuration} />
                  </S.TabContainer>
                );
              } else {
                return null;
              }
            },
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleContent;
