export const wrapIFrameWithinRichText = (content: string) => {
    if (!content.includes('iframe')) return content;
  
    const parser = new DOMParser();
    const { body } = parser.parseFromString(content, 'text/html');
  
    const allElements = Array.from(body.childNodes).map(childNode => {
      const el = childNode as HTMLElement;
  
      if (el.tagName === 'IFRAME') {
        return `<div class="article-iframe-wrapper">${el.outerHTML}</div>`;
      }
  
      return el.outerHTML || el.nodeValue || '';
    });
  
    return allElements.join('');
  };
  