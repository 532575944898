import React, { useState, useEffect } from "react";
import { PageProps, graphql } from "gatsby"; // This part is important - it helps with instructing gatsby to inject the context passed in
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Seo from "../components/Seo/Seo";
import { AllContentStackArticlesData, ArticleContentData } from "../data/ContentStack";
import ArticleHero from "../components/Article/ArticleHero";
import ArticleContent from "../components/Article/NewsArticleContent/NewsArticleContent";
import RelatedFooter from "../components/Article/RelatedFooter";

interface NewsArticlesData {
  allContentstackForgeNewsArticles: AllContentStackArticlesData;
  allArticles: AllContentStackArticlesData;
}

export interface ArticleContext {
  uid: string;
  language: string;
}

interface NewsArticleProps extends PageProps<NewsArticlesData, ArticleContext> {}

const ArticlePage: React.FC<NewsArticleProps> = ({
  data: {
    allContentstackForgeNewsArticles: {
      nodes: [article],
    },
    allArticles: { nodes: allArticles },
  },
}) => {
  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();
  const articleUrl = `https://${siteDomain}/${language}/${article.url.url}`;
  const [relatedArticle, setRelatedArticle] = useState<ArticleContentData | undefined>(undefined);

  useEffect(() => {
    const articleCategory = article.category?.[0].machine_name;
    const relatedCategoryArticles = allArticles.filter(
      (article) => article.category?.[0]?.machine_name === articleCategory,
    );
    const articleToShow = relatedCategoryArticles?.length ? relatedCategoryArticles[0] : allArticles[0];
    setRelatedArticle(articleToShow);
  }, []);

  return (
    <Layout>
      <Seo data={article.article_meta_data} url={articleUrl} locale={language} />
      <ArticleHero {...(article as ArticleContentData)} />
      <ArticleContent {...(article as ArticleContentData)} />
      {relatedArticle && <RelatedFooter content={relatedArticle} />}
    </Layout>
  );
};

export default ArticlePage;

export const query = graphql`
  query ($language: String, $uid: String) {
    ...TranslationQueryFragment
    allContentstackForgeNewsArticles(filter: { publish_details: { locale: { eq: $language } }, uid: { eq: $uid } }) {
      nodes {
        id
        uid
        url {
          url
        }
        article_meta_data {
          pageTitle: page_title
          pageDescription: page_description
          siteImage: site_image {
            url
          }
          url
          siteName: site_name
        }
        authors {
          title
        }
        date
        title
        description
        category {
          title
          machine_name
        }
        banner {
          featured_banner_url {
            url
          }
          hero_banner_url {
            url
          }
          small_carousel_url {
            url
          }
        }
        banner_settings {
          focal_point
          banner_theme
        }
        article_type
        article_body {
          rich_text_editor {
            rich_text
          }
          video_youtube {
            title
            video_id
          }
          gallery {
            title
            images {
              image {
                url
              }
              title
            }
          }
          separator {
            type
          }
          tabs {
            tab_configuration {
              tab_content
              tab_name
            }
          }
        }
      }
    }
    allArticles: allContentstackForgeNewsArticles(
      sort: { fields: date, order: DESC }
      filter: { uid: { ne: $uid }, publish_details: { locale: { eq: $language } } }
    ) {
      nodes {
        date
        title
        authors {
          title
        }
        date
        description
        category {
          title
          machine_name
        }
        banner {
          featured_banner_url {
            url
          }
          small_carousel_url {
            url
          }
        }
        url {
          url
        }
        external_link
      }
    }
  }
`;
