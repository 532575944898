import React from "react";
import { ArticleContentData } from "../../../data/ContentStack";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import NewsCard, { NEWS_CARD_TYPE_CLASSES } from "../../../components/NewsCard/index";
import { Container, Row, Col } from "react-awesome-styled-grid";
import Button from "../../Commons/Button";
import { useSiteMetadata } from "../../../hooks/use-site-metadata";
import ForgeLogo from "../../../images/forge-shape-alt.png";
import Title from "../../Commons/Title";
import LeftArrow from "../../../images/left_arrow.svg";
import * as S from "./styles";

interface ArticleFooterProps {
  content: ArticleContentData;
}

const RelatedFooter: React.FC<ArticleFooterProps> = ({ content }) => {
  const { t } = useTranslation("news");

  const { language } = useI18next();
  const newsLink = `/${language}/news/`;

  return (
    <section>
      <Container>
        <Row justify="center">
          <Col xs={2} sm={2} md={6} lg={12} xl={12}>
            <Row justify="center">
              <Col xs={2} sm={1.8} md={8} lg={12} xl={12}>
                <S.FooterSeperator />
              </Col>
            </Row>
            <S.TitleWrapper className="animation-opacity">
              <S.Forge logo={ForgeLogo} />
              <Title level={1} variable="primary" color="black" uppercase>
                {t("next")}
              </Title>
            </S.TitleWrapper>
            {content && (
              <NewsCard
                newsCardType={NEWS_CARD_TYPE_CLASSES.newsRelated}
                cardType="large"
                uid={content.uid}
                index={0}
                date={content.date}
                category={content.category}
                url={{
                  url: content.url.url,
                }}
                title={content.title}
                description={content.description}
                authors={content.authors}
                banner={{
                  featured_banner_url: {
                    url: content.banner.featured_banner_url.url,
                  },
                  small_carousel_url: {
                    url: content.banner.small_carousel_url.url,
                  },
                }}
                external_link={content.external_link}
              />
            )}
            <S.ButtonWrapper>
              <Button size="large" type={"quinary"} as="a" href={newsLink}>
                <S.ButtonContent>
                  <LeftArrow />
                  <div>{t("back")}</div>
                </S.ButtonContent>
              </Button>
            </S.ButtonWrapper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RelatedFooter;
