import styled, { css } from "styled-components";

export const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .swiper-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .swiper-container {
    max-width: 100%;
    &:after {
      content: '';
      display: block;
      height: 20px;
      width: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: white;
    border: 4px solid #111111;
    color: #30353f;
    height: 48px;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    transition: all 0.2s ease-out, color 0.3s ease-out;
    will-change: transform;
    width: 48px;
    z-index: 100;

    &.swiper-button-disabled {
      opacity: 0.65;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    background-color: #30353f;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: 99;
  }

  .swiper-button-next:hover::after,
  .swiper-button-prev:hover::after {
    transform: translate(0, 0);
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    color: black;
    transform: scale(1.05);
    will-change: transform;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    box-shadow: 4px 4px #111111;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-pagination,
  .swiper-pagination-fraction {
    text-align: left;
    font-weight: 900;
    font-size: 0.75rem;
    bottom: 0;
    position: absolute;
    width: 35px;
  }

  img {
    max-width: 100%;
    height: 90%;
  }
`;

export const Caption = styled.div`
  @keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  };
  bottom: 12px;
  color: #8B8682;
  font-size: 0.75rem;
  margin-left: 45px;
  min-height: 20px;
  opacity: 0;
  position: absolute;
  transform: translateY(100%);
  animation: fadeIn .25s ease-out .35s forwards;
  width: calc(100% - 45px);

  &:after {
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
    bottom: -1px;
    content: '';
    height: 15px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  p {
    max-height: 50px;
    padding-bottom: 15px;
    overflow: scroll;
  }
`;
