import styled from "styled-components"

type Props = {
  level: number
  variable?: string
  color: string
  bold?: boolean
  light?: boolean
  capitalize?: boolean
  uppercase?: boolean
}

const Title = styled.h1<Props>`
  font-family: proxima-nova;
  font-style: normal;
  font-size: ${props =>
    props.level === 1
      ? "34px"
      : props.level === 2
      ? "34px"
      : props.level === 3
      ? "20px"
      : props.level === 4
      ? "24px"
      : props.level === 5
      ? "24px"
      : "1rem"};
  line-height: ${props =>
    props.level === 1
      ? "32px"
      : props.level === 2
      ? "32px"
      : props.level === 3
      ? "24px"
      : props.level === 4
      ? "24px"
      : props.level === 5
      ? "24px"
      : "1.25rem"};
  font-weight: ${props => (props?.bold ? 700 : props?.light ? 300 : 900)};
  color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.secondary.smoke};
  text-transform: ${props =>
    props?.capitalize ? "capitalize" : props?.uppercase ? "uppercase" : "none"};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: ${props =>
      props.level === 1
        ? "56px"
        : props.level === 2
        ? "60px"
        : props.level === 3
        ? "20px"
        : props.level === 4
        ? "24px"
        : props.level === 5
        ? "16px"
        : "1rem"};
    line-height: ${props =>
      props.level === 1
        ? "56px"
        : props.level === 2
        ? "56px"
        : props.level === 3
        ? "24px"
        : props.level === 4
        ? "24px"
        : props.level === 5
        ? "24px"
        : "1.25rem"};
  }
 

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    font-size: ${props =>
      props.level === 1
        ? "72px"
        : props.level === 2
        ? "56px"
        : props.level === 3
        ? "34px"
        : props.level === 4
        ? "32px"
        : props.level === 5
        ? "20px"
        : "1rem"};
    line-height: ${props =>
      props.level === 1
        ? "72px"
        : props.level === 2
        ? "56px"
        : props.level === 3
        ? "32px"
        : props.level === 4
        ? "48px"
        : props.level === 5
        ? "24px"
        : "1.25rem"};
  }
`

export default Title
